

.project-card {
    background-color: #212121;
    padding: 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .project-card:hover {
    background-color: #535353;
  }
  
  .project-image {
    width: 100%;
    height: 12rem;
    object-fit: cover;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .project-name {
    color: #b3b3b3;
    font-size: 1.125rem;
    font-weight: 600;
  }

  /* Media Queries for Responsiveness */
@media (max-width: 900px) {
    .p {
        padding-top: 300px; /* Adjust for smaller header on mobile */
    }
}

@media (max-width: 768px) {
    .p {
        width: 90%;
        padding: 15px;
    }
}

@media (max-width: 480px) {
    .p {
        width: 95%;
        padding: 10px;
    }
}