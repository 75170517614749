.modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow-y: auto;
  padding: 20px 0;
}

.modal-content {
  background-color: #121212;
  border-radius: 0.5rem;
  width: 90%;
  max-width: 900px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  margin: auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #121212;
  border-bottom: 1px solid #333;
  position: sticky;
  top: 0;
  z-index: 10;
}

.modal-title {
  color: #1db954;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}

.close-button {
  color: #b3b3b3;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  line-height: 1;
  padding: 0.25rem;
}

.close-button:hover {
  color: white;
}

.modal-body {
  padding: 1rem;
  overflow-y: auto;
}

.project-video {
  aspect-ratio: 16 / 9;
  margin-bottom: 1rem;
}

.github-link {
  color: #1db954;
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.github-link a:hover {
  text-decoration: underline;
}
.project-tools {
  color: #b3b3b3;
  margin-top: 1rem;
}

.project-description {
  color: #b3b3b3;
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .modal-content {
      width: 95%;
  }

  .modal-title {
      font-size: 1.25rem;
  }

  .close-button {
      font-size: 1.25rem;
  }
}

@media (max-width: 480px) {
  .modal-overlay {
      padding: 0;
  }

  .modal-content {
      width: 100%;
      height: 100%;
      max-height: none;
      border-radius: 0;
  }

  .modal-header {
      padding: 0.5rem;
  }
}