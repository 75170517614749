.about-wrapper {
    padding-top: 160px; /* Adjust this value based on your header's height */
    min-height: 100vh;
    background-color: #212121; /* Darker Gray */
}

.about-container {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Changed from center to flex-start */
    padding: 20px;
    margin: 0 auto;
}

.about-content {
    width: 85%; /* Take up 85% of the container's width */
    background-color: #121212; /* Dark Gray */
    color: #b3b3b3; /* Light Gray */
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

.about-content p {
    font-size: 1rem;
    line-height: 1.6;
    margin: 10px 0;
}
@media (max-width: 900px) {
    .about-wrapper {
        padding-top: 280px; /* Adjust for smaller header on mobile */
    }
}

@media (max-width: 768px) {
    /* Medium screens (e.g., tablets) */
    .about-content {
        width: 90%; /* Take up more space on medium screens */
        padding: 15px;
    }

    .about-content p {
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    /* Small screens (e.g., phones) */
    .about-wrapper {
        padding-top: 280px; /* Adjust for smaller header on mobile */
    }

    .about-content {
        width: 95%; /* Take up more space on small screens */
        padding: 10px;
    }

    .about-content p {
        font-size: 0.85rem;
    }
}