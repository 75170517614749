/* Header.css */

.header {
    background-color: #121212; /* Darker Gray */
    padding: 20px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.nav {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
}

.nav-list {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 0;
    padding: 0;
    justify-content: center;
    width: 100%; /* Ensure full width */
}

.nav-item {
    font-size: 1.2rem; /* Responsive font size */
    font-weight: bold;
    color: #b3b3b3; /* Light Gray */
    padding: 10px 15px;
    text-transform: uppercase;
    border: 2px solid #1DB954; /* Green */
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    text-align: center;
    flex: 1 1 auto; /* Flexible width for items */
    border-radius: 5%;
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden; /* Hide overflow text */
    text-overflow: ellipsis; /* Show ellipsis for overflow */
}

.nav-item:hover {
    background-color: #1DB954; /* Green */
    color: #121212; /* Darker Gray */
}

.nav-item.active {
    background-color: #212121; /* Dark Gray */
    color: #1DB954; /* Green */
}

/* Media Queries for Responsiveness */

/* Small screens (phones, portrait tablets) */
@media (max-width: 600px) {
    .header {
        padding: 10px 5px;
    }

    .nav-list {
        flex-direction: column;
        align-items: center;
    }

    .nav-item {
        font-size: 1rem;
        padding: 8px 10px;
        width: 90%; /* Set a fixed width */
        max-width: 200px; /* Limit maximum width */
        flex: none; /* Remove flex grow and shrink */
        margin-bottom: 5px; /* Add some vertical spacing */
    }
}

/* Medium screens (landscape tablets, small laptops) */
@media (min-width: 601px) and (max-width: 900px) {
    .nav-item {
        font-size: 1.1rem;
        padding: 10px 12px;
        flex: 1 1 calc(50% - 10px); /* Two items per row, accounting for gap */
        max-width: calc(50% - 10px);
    }
}

/* Large screens (desktops, large laptops) */
@media (min-width: 901px) {
    .nav-item {
        flex: 1 1 auto; /* Items take up as much space as needed */
        max-width: 200px; /* Limit maximum width */
    }
}