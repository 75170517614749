/* Contact.css */
.contact-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin: 0 auto;
    min-height: 100vh;
    background-color: #212121; /* Darker Gray */
}

.contact-content {
    width: 85%;
    background-color: #121212; /* Dark Gray */
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.contact-item {
    font-size: 1.2rem;
    font-weight: bold;
    color: #b3b3b3; /* Light Gray */
    padding: 10px 15px;
    text-decoration: none;
    text-transform: uppercase;
    border: 2px solid #1DB954; /* Green */
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    text-align: center;
    min-width: 200px;
    border-radius: 5px;
}

.contact-item:hover {
    background-color: #1DB954; /* Green */
    color: #121212; /* Darker Gray */
}

/* Media Queries for Responsiveness */
@media (max-width: 900px) {
    .contact-container {
        padding-top: 100px; /* Adjust for smaller header on mobile */
    }
}

@media (max-width: 768px) {
    .contact-content {
        width: 90%;
        padding: 15px;
    }

    .contact-item {
        font-size: 1.1rem;
        min-width: 180px;
    }
}

@media (max-width: 480px) {
    .contact-content {
        width: 95%;
        padding: 10px;
    }

    .contact-item {
        font-size: 1rem;
        min-width: 160px;
    }
}