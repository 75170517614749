/* Resume.css */
.resume-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin: 0 auto;
    min-height: 120vh;
    background-color: #212121; /* Darker Gray */
}

.resume-content {
    width: 85%;
    background-color: #121212; /* Dark Gray */
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.resume-pdf {
    width: 100%;
    height: 80vh;
    border: none;
}


/* Media Queries for Responsiveness */
@media (max-width: 900px) {
    .resume-container {
        padding-top: 200px; /* Adjust for smaller header on mobile */
    }
}

@media (max-width: 768px) {
    .resume-content {
        width: 90%;
        padding: 15px;
    }

    .resume-pdf {
        height: 70vh;
    }

    
}

@media (max-width: 480px) {
    .resume-content {
        width: 95%;
        padding: 10px;
    }

    .resume-pdf {
        height: 60vh;
    }


}