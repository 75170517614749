.projects-container {
    background-color: #121212;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin: 0 auto;
    min-height: 120vh;
  }
  
  .projects-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
  }
  @media (min-width: 900px) {
    .projects-container {
      padding-top: 100px; 
    }
  }

@media (min-width: 768px) {
  .projects-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .projects-container {
    padding-top: 300px; 
}
}
 